import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": true,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["website", "startup"],
  "status": ["active"],
  "title": "niklasbuhl.com",
  "subtitle": "Static React website",
  "description": "",
  "years": ["2022"],
  "institutions": ["nb"],
  "location": "cph",
  "keywords": {
    "topics": ["portfolio"],
    "tools": ["react", "gatsby", "html5", "css3", "github actions"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer & Developer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`This Website`}</h2>
    <p>{`Coded all components from scratch. Only using the Gatsby enviroment because it were able to use MDX with frontmatter at the same time.`}</p>
    <p>{`My personal startup, portfolio and sharing space in the cyberverse.
This website is my online playground and personal space on the internet. I want to share my thoughts, writings, showcase my projects - And if I end up putting a dent in the world, or maybe just a smile on someones face - I am happy.`}</p>
    <h2>{`Startup`}</h2>
    <h2>{`Workshops and Events`}</h2>
    <h3>{`Robotic Workshop`}</h3>
    <p>{`Workshop for DTU student about UR5, MiR and NAO robots`}</p>
    <h3>{`Arduino`}</h3>
    <p>{`A workshop for DTU Smart Library colleagues`}</p>
    <h3>{`Lego Mindstorms`}</h3>
    <p>{`A workshop for DTU Smart Library colleagues`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      